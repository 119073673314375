import {KeyValueEnum} from './app/interfaces/key-value-enum.interface';


export function enumToKeyValue(enumObject: any): KeyValueEnum[] {
  const keys = Object.keys(enumObject);
  const values = Object.values(enumObject);
  const keyValues = [] as KeyValueEnum[];

  for(let i in keys) {
    keyValues.push(<KeyValueEnum>{key: keys[i], value: values[i]});
  }

  return keyValues;
}
