/**
 * Dokumente
 * API für Dokumente
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum für die standardisierten Ablageorte 
 */
export enum StandardablageortDTO {
    Fakturierung = 'Fakturierung',
    FakturierungAnhaenge = 'Anhaenge',
    FakturierungBuchungsliste = 'Buchungsliste',
    FakturierungErgebnisprotokoll = 'Ergebnisprotokoll',
    FakturierungFakturierungslauf = 'Fakturierungslauf',
    FakturierungFakturierungsliste = 'Fakturierungsliste',
    FakturierungProduktpreisliste = 'Produktpreisliste',
    GrundUndBoden = 'Grund und Boden',
    GrundUndBodenEntnahme = 'Entnahme',
    GrundUndBodenFlurbereinigung = 'Flurbereinigung',
    GrundUndBodenSonstiges = 'Grund und Boden Sonstiges',
    GrundUndBodenVertraege = 'Grund und Boden Verträge',
    GrundUndBodenVerzeichnisse = 'Verzeichnisse',
    Lohnbuchhaltung = 'Lohnbuchhaltung',
    LohnbuchhaltungAllgemein = 'Lohnbuchhaltung Allgemein',
    LohnbuchhaltungBestandsnachweise = 'Bestandsnachweise',
    LohnbuchhaltungDatenArbeitnehmer = 'Daten Arbeitnehmer',
    LohnbuchhaltungLohnabrechnung = 'Lohnabrechnung',
    LohnbuchhaltungLohnprüfungen = 'Lohnprüfungen',
    Rechnungswesen = 'Rechnungswesen',
    RechnungswesenAllgemein = 'Rechnungswesen Allgemein',
    RechnungswesenFinanzbuchhaltung = 'Finanzbuchhaltung',
    RechnungswesenJahresabschluss = 'Jahresabschluss',
    Sonstiges = 'Sonstiges',
    SonstigesAllgemein = 'Sonstiges Allgemein',
    Steuern = 'Steuern',
    SteuernAktenvermerk = 'Aktenvermerk',
    SteuernAllgemein = 'Steuern Allgemein',
    SteuernBetriebspruefungen = 'Betriebsprüfungen',
    SteuernEinheitsbewertung = 'Einheitsbewertung',
    SteuernEinkommensteuer = 'Einkommensteuer',
    SteuernErbschaftSchenkungsteuer = 'Erbschaft-/Schenkungsteuer',
    SteuernGesonderteUndEinheitlicheFeststellung = 'Gesonderte und einheitliche Feststellung',
    SteuernGewerbesteuer = 'Gewerbesteuer',
    SteuernGrundGrunderwerbsteuer = 'Grund-/Grunderwerbsteuer',
    SteuernKoerperschaftsteuer = 'Köperschaftsteuer',
    SteuernLohnsteuer = 'Lohnsteuer',
    SteuernUmsatzsteuer = 'Umsatzsteuer',
    Vertraege = 'Verträge',
    VertraegeGesellschaftsvertraege = 'Gesellschaftsverträge',
    VertraegeHofpachtvertraege = 'Hofpachtverträge',
    VertraegeMietPachtvertraege = 'Mietpachtverträe',
    VertraegeSonstiges = 'Verträge Sonstiges',
    VertraegeUebergabevertraege = 'Übergabeverträge',
    Zahlungsverkehr = 'Zahlungsverkehr',
    ZahlungsverkehrProtokoll = 'Protokoll'
};

