/**
 * Dokumente
 * API für Dokumente
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PageableDokumentAllOfDTO { 
    sorting?: Array<PageableDokumentAllOfDTOSortingEnum>;
}
export enum PageableDokumentAllOfDTOSortingEnum {
    Bezeichnungasc = 'BEZEICHNUNGASC',
    Bezeichnungdesc = 'BEZEICHNUNGDESC',
    Erstelltasc = 'ERSTELLTASC',
    Erstelltdesc = 'ERSTELLTDESC',
    Geaendertasc = 'GEAENDERTASC',
    Geaendertdesc = 'GEAENDERTDESC',
    Jahrasc = 'JAHRASC',
    Jahrdesc = 'JAHRDESC',
    Dateigroesseasc = 'DATEIGROESSEASC',
    Dateigroessedesc = 'DATEIGROESSEDESC',
    Bearbeiterasc = 'BEARBEITERASC',
    Bearbeiterdesc = 'BEARBEITERDESC'
};



