import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {InhaberResolver} from './resolver/inhaber.resolver';
import {InteractiveAnalysesResolver} from './resolver/interactive-analyses.resolver';


const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    loadChildren: () => import(
      './routes/overview-routing.module'
      ).then(m => m.OverviewRoutingModule),
    canActivate: [AuthGuard],
    resolve: [InhaberResolver, InteractiveAnalysesResolver],
  },
  {
    path: 'interactive-analysis',
    loadChildren: () => import(
      './routes/interactive-analysis-routing.module'
      ).then(m => m.InteractiveAnalysisRoutingModule),
    canActivate: [AuthGuard],
    resolve: [InhaberResolver, InteractiveAnalysesResolver],
  },
  {
    path: 'widget/utility-widget',
    loadChildren: () => import('./modules/widget/utility-widget/utility-widget.module').then(m => m.UtilityWidgetModule),
    canActivate: [AuthGuard],
    resolve: [InhaberResolver],
    data: {
      /*
       * INFO: Wenn die Fehlermeldungen manuell embedded werden sollen, hier true. Ansonsten wird per redirect die
       * Route auf die error-Seite umgeleitet.
       */
      embedErrors: true,
    },
  },
  {
    path: 'widget/regionalwert-widget',
    loadChildren: () => import('./modules/widget/regionalwert-widget/regionalwert-widget.module').then(m => m.RegionalwertWidgetModule),
    canActivate: [AuthGuard],
    resolve: [InhaberResolver],
    data: {
      /*
       * INFO: Wenn die Fehlermeldungen manuell embedded werden sollen, hier true. Ansonsten wird per redirect die
       * Route auf die error-Seite umgeleitet.
       */
      embedErrors: true,
    },
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/impressum/impressum.module').then(m => m.ImpressumModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    canActivate: [AuthGuard],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
