import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DashboardService} from '../../openapi/tableau-openapi';
import {InteractiveAnalysesActions} from '../actions/interactive-analyses.actions';
import {concatMap, EMPTY, map, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {Router} from '@angular/router';
import {LatestLoadedDashboardData} from '../../interfaces/latest-loaded-dashboard-data';


@Injectable()
export class InteractiveAnalysesEffects {


  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private logger: NGXLogger,
    private router: Router,
  ) {
  }

  readonly loadInteractiveAnalysesAllInhaber$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(InteractiveAnalysesActions.loadInteractiveAnalysesAll),
        concatMap(() => {
          return this.dashboardService.readDashboards()
            .pipe(
              catchError(error => {
                this.logger.error('Die Liste der interaktiven Analysen konnte nicht geladen werden.', error);
                return EMPTY;
              }),
            );
        }),
        map(response => InteractiveAnalysesActions.interactiveAnalysesAllLoaded({response})),
      )
  );

  readonly loadInteractiveAnalysesUrl$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(InteractiveAnalysesActions.loadInteractiveAnalysesUrl),
        concatMap(action => {
          return this.dashboardService.readDashboardUrl(
            action.interactiveAnalysis.detailData.nameIdentifier!,
            action.inhaberId,
          ).pipe(
            catchError(error => {
              this.logger.error('Interaktive Analysen konnten nicht aufgerufen werden.', error);
              return EMPTY;
            }),
            map(url => {
              const data: LatestLoadedDashboardData = {
                url,
                interactiveAnalysisData: action.interactiveAnalysis,
              };
              return data;
            }),
            tap(data => {
              const url = '/interactive-analysis/inhaber/'
                + data.interactiveAnalysisData.inhaberId
                + '?dashboard-identifier=' + data.interactiveAnalysisData.detailData.nameIdentifier;

              this.router.navigateByUrl(url);
            }),
            map(data => {
              return InteractiveAnalysesActions.interactiveAnalysesUrlLoaded({data});
            })
          );
        }),
      ),
  );
}
