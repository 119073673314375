import {createSelector} from '@ngrx/store';
import {AppState} from '../../interfaces/app-state.interface';


export class InhaberSelectors {

  private static selectInhabersLoaded = (state: AppState) => state.inhaber?.inhabersLoaded;
  private static selectInhabersLoading = (state: AppState) => state.inhaber?.inhabersLoading;
  private static selectInhaberDtos = (state: AppState) => state.inhaber?.inhaberDtos;
  private static selectSelectedId = (state: AppState) => state.inhaber?.selectedId;
  private static selectElectedId = (state: AppState) => state.inhaber?.electedId;

  public static inhabersLoaded = createSelector(
    this.selectInhabersLoaded,
    (availableLoaded) => {
      return availableLoaded;
    }
  );

  public static inhaberDtos = createSelector(
    this.selectInhaberDtos,
    (available) => {
      return available;
    }
  );

  public static selectedId = createSelector(
    this.selectSelectedId,
    (selectedId) => {
      return selectedId;
    }
  );

  public static elected = createSelector(
    this.selectElectedId,
    this.selectInhaberDtos,
    (electedId, dtos) => {
      return dtos?.filter(item => item.id === electedId).at(0);
    }
  );

  public static validateSelectedId = createSelector(
    this.selectSelectedId,
    this.inhaberDtos,
    (selectedId, inhaberDtos) => {
      return {selectedId, inhaberDtos};
    }
  );

  public static isEmpty = createSelector(
    this.selectInhabersLoaded,
    this.selectInhabersLoading,
    this.selectInhaberDtos,
    (inhabersLoaded, inhabersLoading, inhaberDtos) => {
      return inhabersLoaded && !inhabersLoading && inhaberDtos !== undefined && inhaberDtos?.length === 0;
    }
  );

}
