import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {
  AuthInterceptor as OidcAuthInterceptor,
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader
} from 'angular-auth-oidc-client';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {map, mergeMap, take, tap} from 'rxjs';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfigModule} from './config/config.module';
import {ConfigService} from './config/service/config.service';
import {environment} from '../environments/environment';
import {ApiModule as DokumentApiModule, Configuration as DokumentConfiguration} from './openapi/dokument-openapi';
import {ApiModule as TableauApiModule, Configuration as TableauConfiguration} from './openapi/tableau-openapi';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {PortalTokenService} from './portal/portal-token.service';
import {AuthInterceptor} from './auth/auth.interceptor';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {WithLeftPanelComponent} from './templates/with-left-panel/with-left-panel.component';
import {DefaultComponent} from './templates/default/default.component';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {metaReducers, reducers} from './store/reducers/reducers';
import {InhaberEffects} from './store/effects/inhaber.effects';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import * as Sentry from '@sentry/angular-ivy';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {filter} from 'rxjs/operators';
import {MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/legacy-tooltip';
import {InteractiveAnalysesEffects} from './store/effects/interactive-analyses.effects';
import {ErrorpageEffects} from './store/effects/errorpage.effects';
import {Interceptor} from './http-interceptors/interceptor';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    WithLeftPanelComponent,
    DefaultComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    // Config
    ConfigModule,
    // Logging
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO, // default logging level
      enableSourceMaps: !environment.production,
    }),
    // Auth
    // TODO: Auth ggf. in eigenes Module auslagern
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (configService: ConfigService) => {
          const configObservable = configService.loaded$
            .pipe(
              take(1),
              map(config => {
                const authConfig: OpenIdConfiguration = {
                  // loaded
                  authority: config.auth.authority,
                  clientId: config.auth.clientId,
                  scope: config.auth.scope,
                  logLevel: config.auth.logLevel,
                  renewTimeBeforeTokenExpiresInSeconds: config.auth.renewTimeBeforeTokenExpiresInSeconds,
                  // predefined
                  redirectUrl: window.location.origin,
                  postLogoutRedirectUri: window.location.origin,
                  responseType: 'code',
                  silentRenew: true,
                  triggerAuthorizationResultEvent: true,
                  ignoreNonceAfterRefresh: true,
                  useRefreshToken: true,
                  // INFO: Bei anpassungen auch AuthInterceptor.matchesSecureRoots() anpassen
                  secureRoutes: [config.dokument.apiBaseUrl, config.tableau.apiBaseUrl],
                };
                return authConfig;
              }),
            );
          return new StsConfigHttpLoader(configObservable);
        },
        deps: [ConfigService],
      },
    }),
    DokumentApiModule.forRoot(() => new DokumentConfiguration()),
    TableauApiModule.forRoot(() => new TableauConfiguration()),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
      }
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot([
      InhaberEffects,
      InteractiveAnalysesEffects,
      ErrorpageEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Dokumentenpostfach App',
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  exports: [
    AuthModule,
  ],
  providers: [
    OidcAuthInterceptor,
    {
      // INFO: Interceptor um automatisch da OAuth Token zu setzen
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      // INFO: Initializer das Logging
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, logger: NGXLogger) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              logger.updateConfig({
                ...logger.getConfigSnapshot(),
                level: value.logging.level
              });
            }),
          );
      },
      deps: [ConfigService, NGXLogger],
      multi: true,
    },
    {
      // INFO: Initializer für Sentry Integration
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        contextInfoService: ContextInfoService,
        logger: NGXLogger,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(config => {
              const sentry = config.sentry;
              const info = environment.info;
              if (sentry) {
                Sentry.init({
                  enabled: sentry.enabled,
                  debug: sentry.debug,
                  environment: config.environment,
                  release: info.commit,
                  dsn: sentry.dsn,
                  sampleRate: sentry.sampleRate,
                  autoSessionTracking: false,
                  enableTracing: false,
                  // maxBreadcrumbs nur erhöhen, wenn niemals sensiblen Daten mitgeschrieben werden
                  // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/default/#breadcrumbs
                  maxBreadcrumbs: 0,
                  initialScope: {
                    extra: {
                      'info': info,
                    },
                  },
                  beforeSend: (event) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)) {
                      return event;
                    }
                    logger.debug('Error reporting is disabled, dropping event');
                    return null;
                  },
                  beforeSendTransaction: (event) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (
                      contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS)
                      && contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)
                    ) {
                      return event;
                    }
                    logger.debug('Error reporting or analytics are disabled, dropping transaction event');
                    return null;
                  },
                  beforeBreadcrumb: (breadcrumb) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (
                      contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS)
                      && contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)
                    ) {
                      return breadcrumb;
                    }
                    logger.debug('Error reporting or analytics are disabled, dropping breadcrumb');
                    return null;
                  },
                });
              }
            }),
          );
      },
      deps: [
        ConfigService,
        ContextInfoService,
        NGXLogger,
      ],
      multi: true,
    },
    {
      // INFO: Angular Sentry ErrorHandler
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      // INFO: Initializer der die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        dokumentConfiguration: DokumentConfiguration,
        tableauConfiguration: TableauConfiguration,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              dokumentConfiguration.basePath = value.dokument.apiBaseUrl;
              tableauConfiguration.basePath = value.tableau.apiBaseUrl;
            }),
          );
      },
      deps: [ConfigService, DokumentConfiguration, TableauConfiguration],
      multi: true,
    },
    {
      // INFO: Initializer für Portal integration die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        portalMessageBroker: PortalMessageBroker,
        portalTokenService: PortalTokenService,
        oidcSecurityService: OidcSecurityService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            mergeMap(config => {
              return fromPromise(portalMessageBroker.init(config.portal.targetOrigin))
                .pipe(map(() => config));
            }),
            mergeMap(config => {
              return fromPromise(portalTokenService.init(config.auth.renewTimeBeforeTokenExpiresInSeconds))
                .pipe(map(() => config));
            }),
            tap(() => {
              const runningEmbedded = portalMessageBroker.isRunningInPortal();
              Sentry.setTag('runningEmbedded', runningEmbedded);

              if (runningEmbedded) {
                const decoded = jwtDecode<JwtPayload>(portalTokenService.getAdnovaToken());
                const token = decoded as any;

                const email = token.email;
                const username = token.preferred_username;
                const name = token.name;

                Sentry.withScope(scope => {
                  let user = scope.getUser();
                  if (!user) {
                    user = {};
                  }
                  user.id = decoded.sub;
                  user.email = email;
                  user.username = username;
                  user['name'] = name;

                  scope.setUser(user);
                });
              } else {
                oidcSecurityService.getAccessToken()
                  .pipe(
                    filter(value => {
                      if (value) {
                        return true;
                      }
                      return false;
                    }),
                  )
                  .subscribe(accessToken => {
                    const decoded = jwtDecode<JwtPayload>(accessToken);
                    const token = decoded as any;

                    const email = token.email;
                    const username = token.preferred_username;
                    const name = token.name;

                    Sentry.withScope(scope => {
                      let user = scope.getUser();
                      if (!user) {
                        user = {};
                      }
                      user.id = decoded.sub;
                      user.email = email;
                      user.username = username;
                      user['name'] = name;

                      scope.setUser(user);
                    });
                  });
              }
            }),
          );
      },
      deps: [
        ConfigService,
        PortalMessageBroker,
        PortalTokenService,
        OidcSecurityService,
      ],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true,
        showDelay: 600,
        hideDelay: 150,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
