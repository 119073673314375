/**
 * Tableau
 * API für das Erstellen von Dashboard URLs für Tableau, die URL enthält ein Ticket mit dem der Zugriff auf Tableau gewährleistet wird. Zusätzlich werden für Benutzer die Berechtigungen für den Zugriff auf diese Daten in der Tableau-Datenbank gesetzt. 
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DashboardsAuswahlExtendedResponseDTO } from '../model/models';
import { DashboardsAuswahlResponseDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Gibt die URL für ein Dashboard und zu dem gewählten Betrieb zurück
     * Gibt die URL für ein Dashboard und zu dem gewählten Betrieb zurück
     * @param dashboard Das Dashboard zu dem die URL erstellt werden soll
     * @param firmaUuid Die UUID zur Firma zu der das Dashboard erstellt werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboardUrl(dashboard: string, firmaUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<string>;
    public readDashboardUrl(dashboard: string, firmaUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<HttpResponse<string>>;
    public readDashboardUrl(dashboard: string, firmaUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain'}): Observable<HttpEvent<string>>;
    public readDashboardUrl(dashboard: string, firmaUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain'}): Observable<any> {
        if (dashboard === null || dashboard === undefined) {
            throw new Error('Required parameter dashboard was null or undefined when calling readDashboardUrl.');
        }
        if (firmaUuid === null || firmaUuid === undefined) {
            throw new Error('Required parameter firmaUuid was null or undefined when calling readDashboardUrl.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-extern) required
        credential = this.configuration.lookupCredential('oauth-extern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/v1/tableau/dashboard/${encodeURIComponent(String(dashboard))}/url/${encodeURIComponent(String(firmaUuid))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt die URL für ein Dashboard und zu dem gewählten Betrieb zurück
     * Gibt die URL für ein Dashboard und zu dem gewählten Betrieb zurück
     * @param betriebsNummer Die Betriebsnummer des Betriebes zu dem das Dashboard erstellt werden soll
     * @param dashboard Das Dashboard zu dem die URL erstellt werden soll
     * @param benutzerUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboardUrlIntern(betriebsNummer: number, dashboard: string, benutzerUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public readDashboardUrlIntern(betriebsNummer: number, dashboard: string, benutzerUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public readDashboardUrlIntern(betriebsNummer: number, dashboard: string, benutzerUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public readDashboardUrlIntern(betriebsNummer: number, dashboard: string, benutzerUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebsNummer === null || betriebsNummer === undefined) {
            throw new Error('Required parameter betriebsNummer was null or undefined when calling readDashboardUrlIntern.');
        }
        if (dashboard === null || dashboard === undefined) {
            throw new Error('Required parameter dashboard was null or undefined when calling readDashboardUrlIntern.');
        }
        if (benutzerUuid === null || benutzerUuid === undefined) {
            throw new Error('Required parameter benutzerUuid was null or undefined when calling readDashboardUrlIntern.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-intern) required
        credential = this.configuration.lookupCredential('oauth-intern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/v1/tableau/dashboard/benutzer/${encodeURIComponent(String(benutzerUuid))}/${encodeURIComponent(String(dashboard))}/url/${encodeURIComponent(String(betriebsNummer))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine Liste von anzeigbaren Dashboards aus
     * Gibt eine Map aufgeschlüsselt nach Betrieben und den dazu anzeigbaren Dashboards zurück. Dashboards tauchen nur auf wenn zum Betrieb auch Daten vorhanden sind. Funktioniert inhaltlich genauso wie readDashboardsIntern, nutzt aber eine andere Security. Dies kann abhängig von der Anzahl Firmen und Mandanten länger dauern!
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboards(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DashboardsAuswahlExtendedResponseDTO>;
    public readDashboards(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DashboardsAuswahlExtendedResponseDTO>>;
    public readDashboards(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DashboardsAuswahlExtendedResponseDTO>>;
    public readDashboards(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-extern) required
        credential = this.configuration.lookupCredential('oauth-extern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DashboardsAuswahlExtendedResponseDTO>(`${this.configuration.basePath}/api/v1/tableau/dashboards`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine Liste von anzeigbaren Dashboards aus
     * Gibt die anzeigbaren Dashboards für den Betrieb zurück. Dashboards tauchen nur auf wenn zum Betrieb auch Daten vorhanden sind und der Benutzer die Berechtigungen besitzt. Funktioniert inhaltlich genauso wie readDashboardsIntern, nutzt aber eine andere Security.
     * @param firmaUuid Die UUID zur Firma zu der das Dashboard erstellt werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboardsBetrieb(firmaUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DashboardsAuswahlExtendedResponseDTO>;
    public readDashboardsBetrieb(firmaUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DashboardsAuswahlExtendedResponseDTO>>;
    public readDashboardsBetrieb(firmaUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DashboardsAuswahlExtendedResponseDTO>>;
    public readDashboardsBetrieb(firmaUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (firmaUuid === null || firmaUuid === undefined) {
            throw new Error('Required parameter firmaUuid was null or undefined when calling readDashboardsBetrieb.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-extern) required
        credential = this.configuration.lookupCredential('oauth-extern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DashboardsAuswahlExtendedResponseDTO>(`${this.configuration.basePath}/api/v1/tableau/dashboards/betrieb/${encodeURIComponent(String(firmaUuid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine Liste von anzeigbaren Dashboards aus
     * Gibt die anzeigbaren Dashboards für den Betrieb zurück. Dashboards tauchen nur auf wenn zum Betrieb auch Daten vorhanden sind und der Benutzer die Berechtigungen besitzt. Funktioniert inhaltlich genauso wie readDashboardsIntern, nutzt aber eine andere Security.
     * @param benutzerUuid 
     * @param betriebsNummer Die Betriebsnummer des Betriebes zu dem das Dashboard erstellt werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboardsBetriebIntern(benutzerUuid: string, betriebsNummer: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DashboardsAuswahlResponseDTO>;
    public readDashboardsBetriebIntern(benutzerUuid: string, betriebsNummer: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DashboardsAuswahlResponseDTO>>;
    public readDashboardsBetriebIntern(benutzerUuid: string, betriebsNummer: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DashboardsAuswahlResponseDTO>>;
    public readDashboardsBetriebIntern(benutzerUuid: string, betriebsNummer: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (benutzerUuid === null || benutzerUuid === undefined) {
            throw new Error('Required parameter benutzerUuid was null or undefined when calling readDashboardsBetriebIntern.');
        }
        if (betriebsNummer === null || betriebsNummer === undefined) {
            throw new Error('Required parameter betriebsNummer was null or undefined when calling readDashboardsBetriebIntern.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-intern) required
        credential = this.configuration.lookupCredential('oauth-intern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DashboardsAuswahlResponseDTO>(`${this.configuration.basePath}/api/v1/tableau/dashboards/benutzer/${encodeURIComponent(String(benutzerUuid))}/betrieb/${encodeURIComponent(String(betriebsNummer))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt eine Liste von anzeigbaren Dashboards aus
     * Gibt eine Map aufgeschlüsselt nach Betrieben und den dazu anzeigbaren Dashboards zurück. Dashboards tauchen nur auf wenn zum Betrieb auch Daten vorhanden sind. Funktioniert inhaltlich genauso wie readDashboards, nutzt aber eine andere Security. Wird keine BetriebsNummer übergeben werden alle Firmen mit Zugriff und ausreichenden Berechtigungen überprüft. Dies kann abhängig von der Anzahl Firmen und Mandanten länger dauern!
     * @param benutzerUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDashboardsIntern(benutzerUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DashboardsAuswahlResponseDTO>;
    public readDashboardsIntern(benutzerUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DashboardsAuswahlResponseDTO>>;
    public readDashboardsIntern(benutzerUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DashboardsAuswahlResponseDTO>>;
    public readDashboardsIntern(benutzerUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (benutzerUuid === null || benutzerUuid === undefined) {
            throw new Error('Required parameter benutzerUuid was null or undefined when calling readDashboardsIntern.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-intern) required
        credential = this.configuration.lookupCredential('oauth-intern');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DashboardsAuswahlResponseDTO>(`${this.configuration.basePath}/api/v1/tableau/dashboards/benutzer/${encodeURIComponent(String(benutzerUuid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
