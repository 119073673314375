import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ErrorpageActions} from '../actions/errorpage.actions';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';


@Injectable()
export class ErrorpageEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private logger: NGXLogger,
  ) {
  }

  /**
   * Ist der Dokument-Service aufgrund eines Server Errors (HTTP 5xx) nicht verfügbar,
   * so zeigen wir die Service-Unavailable Errorpage an.
   */
  readonly dokumentServiceUnavailable$ = createEffect(
    () => this.actions$.pipe(
      ofType(ErrorpageActions.dokumentServiceUnavailable),
      tap(({
             status,
             url,
           }) => {
        this.logger.error('show dokument-service-unavailable error-page.', 'status:', status, 'url:', url);

        this.router.navigateByUrl('/error/service-unavailable');
      }),
    ), {dispatch: false}
  );

  /**
   * Liefert das Backend eine leere Liste an Inhabern,
   * so zeigen wir die Betrieb-Not-Found Errorpage an.
   */
  readonly betriebNotFound$ = createEffect(
    () => this.actions$.pipe(
      ofType(ErrorpageActions.betriebNotFound),
      tap(() => {
        this.logger.error('show betrieb-not-found error-page.');

        this.router.navigateByUrl('/error/betrieb-not-found');
      }),
    ), {dispatch: false}
  );
}
