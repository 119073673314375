import {createReducer, on} from '@ngrx/store';
import {DocumentsActions} from '../actions/documents.actions';
import {enumToKeyValue} from '../../../helper';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {DocumentsState} from '../../interfaces/documents-state.interface';
import {InhaberAblageorte} from '../../interfaces/inhaber-ablageorte.interface';
import {AblageortDokumente} from '../../interfaces/ablageort-dokumente.interface';
import {displayedAblageorte} from '../../displayed-ablageorte';
import {StandardablageortDTO} from '../../openapi/dokument-openapi';


export const initialDocumentsState: DocumentsState = {
  filter: {
    show: 'folders',
    pagination: {
      pageSize: 25,
      pageIndex: 0,
      length: 0,
    },
  },
  ablageorteLoading: [],
  ablageorteLoaded: [],
  standardAblageorte: enumToKeyValue(StandardablageortDTO),
  allDocumentsLoaded: false,
  allDocumentsLoading: false,
  allDocumentsCount: 0,
  reloadUtilityWidget: false,
};

export const documentsReducer = createReducer(
  initialDocumentsState,

  on(
    DocumentsActions.changeShowContext,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        show: action.show,
        path: action.path,
        searchtext: action.show === 'folders' ? '' : state.filter.searchtext,
      }
    })
  ),

  on(
    DocumentsActions.loadAllAblageorte,
    (state, action) => ({
      ...state,
      ablageorteLoading: [
        ...state.ablageorteLoading.filter(item => action.inhaberIds.indexOf(item) === -1),
        ...action.inhaberIds,
      ],
    })
  ),

  on(
    DocumentsActions.changeSearchtext,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        searchtext: action.searchtext,
      }
    })
  ),

  on(
    DocumentsActions.ablageorteLoaded,
    (state, action) => {
      const filteredAblageorte = action.ablageorte;

      const newAblageorte = {
        inhaberId: action.inhaberId,
        ablageorte: filteredAblageorte,
      };

      const ablageorteLoading = [
        ...state.ablageorteLoading.filter(item => item !== action.inhaberId) || [],
      ];

      // INFO: Wenn sich die Ablageorte eines Inhabers nicht verändert haben, wird nichts weiter geändert.
      if (state.inhaberAblageorte?.find(ablageorte => JSON.stringify(ablageorte.ablageorte) === JSON.stringify(newAblageorte.ablageorte))) {
        return {
          ...state,
          ablageorteLoading,
          ablageorteLoaded: [
            ...state.ablageorteLoaded.filter(item => item !== action.inhaberId) || [],
            action.inhaberId,
          ],
        };
      }

      // INFO: Ansonsten werden die alten Daten durch die neuen ersetzt.
      return {
        ...state,
        inhaberAblageorte: [
          ...state.inhaberAblageorte?.filter(item => item.inhaberId !== action.inhaberId) || [],
          {
            inhaberId: action.inhaberId,
            ablageorte: filteredAblageorte,
            ablageortDokumente: [],
          }
        ],
        ablageorteLoading,
        ablageorteLoaded: [
          ...state.ablageorteLoaded,
          action.inhaberId,
        ],
      };
    }
  ),

  on(
    DocumentsActions.loadDocuments,
    (state, action) => {

      if (state.filter.show === 'folders') {

        const otherInhaberAblageorte = state.inhaberAblageorte?.filter(
          (item: InhaberAblageorte) => item.inhaberId !== action.inhaberId) || [];

        let inhaberAblageorte = state.inhaberAblageorte?.filter(
          (item: InhaberAblageorte) => item.inhaberId === action.inhaberId).at(0) || {
          inhaberId: action.inhaberId,
          ablageorte: [],
          ablageortDokumente: []
        } as InhaberAblageorte;


        const otherAblageortDokumente = inhaberAblageorte?.ablageortDokumente.filter(
          (item: AblageortDokumente) => item.ablageort !== action.filter.path) || [];

        let ablageortDokumente = inhaberAblageorte?.ablageortDokumente.filter(
          (item: AblageortDokumente) => item.ablageort === action.filter.path).at(0) || {
          loaded: false,
          loading: true,
          dokumente: [],
          ablageort: action.filter.path,
        } as AblageortDokumente;

        ablageortDokumente = {
          ...ablageortDokumente,
          loading: true,
        };

        otherAblageortDokumente.push(ablageortDokumente);

        inhaberAblageorte = {
          ...inhaberAblageorte,
          ablageortDokumente: otherAblageortDokumente,
        };

        otherInhaberAblageorte.push(inhaberAblageorte);

        return {
          ...state,
          inhaberAblageorte: otherInhaberAblageorte,
        };

      } else if (state.filter.show === 'documents') {

        return {
          ...state,
          allDocumentsLoading: true,
          allDocumentsLoaded: false,
        };

      } else {

        return {...state};
      }
    }
  ),

  on(
    DocumentsActions.documentsLoaded,
    (state, action) => {

      if (state.filter.show === 'folders') {

        const otherInhaberAblageorte = state.inhaberAblageorte?.filter(
          (item: InhaberAblageorte) => item.inhaberId !== action.inhaberId) || [];

        let inhaberAblageorte = state.inhaberAblageorte?.filter(
          (item: InhaberAblageorte) => item.inhaberId === action.inhaberId).at(0) || {
          inhaberId: action.inhaberId,
          ablageorte: [],
          ablageortDokumente: []
        } as InhaberAblageorte;


        const otherAblageortDokumente = inhaberAblageorte?.ablageortDokumente.filter(
          (item: AblageortDokumente) => item.ablageort !== action.ablageort) || [];

        let ablageortDokumente = inhaberAblageorte?.ablageortDokumente.filter(
          (item: AblageortDokumente) => item.ablageort === action.ablageort).at(0) || {
          loaded: true,
          loading: false,
          dokumente: action.dokumente,
          ablageort: action.ablageort,
        } as AblageortDokumente;

        ablageortDokumente = {
          ...ablageortDokumente,
          loading: false,
          loaded: true,
          dokumente: action.dokumente
        };

        otherAblageortDokumente.push(ablageortDokumente);

        inhaberAblageorte = {
          ...inhaberAblageorte,
          ablageortDokumente: otherAblageortDokumente,
        };

        otherInhaberAblageorte.push(inhaberAblageorte);

        return {
          ...state,
          inhaberAblageorte: otherInhaberAblageorte,
        };

      } else if (state.filter.show === 'documents') {

        return {
          ...state,
          allDocumentsLoading: false,
          allDocumentsLoaded: true,
          allDocuments: action.dokumente,
        };

      } else {

        return {...state};

      }
    }
  ),

  on(
    DocumentsActions.setPagination,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        pagination: action.page,
      }
    })
  ),

  on(
    DocumentsActions.updateDocumentSuccess,
    (state, action) => {

      /*
       * INFO: Aktualisierung der InhaberAblageorte.
       * Hier wird im Array selbst die entsprechende Entity geändert, sodass kein Reload
       * benötigt wird. Dazu findet ein Immutable Update mithilfe von Array.map() statt.
       */
      const inhaberAblageorte = state.inhaberAblageorte?.map(
        ablageorte => {
          if (ablageorte.inhaberId !== action.document.inhaberId) {
            return ablageorte;
          }

          const ablageorteReadCpy = ablageorte.ablageorte.map(
            ablageort => {
              const rootBezeichnung = displayedAblageorte.find(displayedOrt => {
                const ablageortEnum = state.standardAblageorte.find(stdOrt => {
                  return stdOrt.value === action.document.ablageort?.standardablageort;
                })?.key;
                const displayedEnum = state.standardAblageorte.find(stdOrt => stdOrt.value === displayedOrt)?.key;

                return displayedEnum && ablageortEnum?.startsWith(displayedEnum);
              });

              if (ablageort.bezeichnung !== rootBezeichnung
                && ablageort.bezeichnung !== action.document.ablageort?.standardablageort) {
                return ablageort;
              }

              return {
                ...ablageort,
                anzahlUngelesenerDokumente: (ablageort.anzahlUngelesenerDokumente || 1) - 1,
              };
            }
          );

          const ablageort = state.standardAblageorte.find(
            ablageort => ablageort.value === action.document.ablageort?.standardablageort
          );
          return {
            ...ablageorte,
            ablageorte: ablageorteReadCpy,
            ablageortDokumente: ablageorte.ablageortDokumente.map(ablageortDokumente => {
              if (ablageortDokumente.ablageort !== ablageort?.key) {
                return ablageortDokumente;
              }

              return {
                ...ablageortDokumente,
                dokumente: ablageortDokumente.dokumente.map(dokument => {
                  if (dokument.id !== action.document.id) {
                    return dokument;
                  }

                  return {
                    ...dokument,
                    ...action.requestDto,
                  };
                }),
              };
            }),
          };
        }
      );

      // INFO: Aktualisierung von AllDocuments
      const allDocuments = state.allDocuments?.map(document => {
        if (document.id !== action.document.id) {
          return document;
        }
        return {
          ...document,
          ...action.requestDto,
        };
      });

      return {
        ...state,
        inhaberAblageorte,
        allDocuments,
      };
    },
  ),

  on(
    DocumentsActions.setFilterPaginationLength,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        pagination: {
          ...(state.filter.pagination || {} as PageEvent),
          length: action.length,
        },
      }
    })
  ),

  on(
    DocumentsActions.setFilterPaginationPageIndex,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        pagination: {
          ...(state.filter.pagination || {} as PageEvent),
          pageIndex: action.pageIndex,
        },
      }
    })
  ),

  on(
    DocumentsActions.setSort,
    (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        sort: action.sort,
      }
    })
  ),

  on(
    DocumentsActions.setAllDocumentsCount,
    (state, action) => ({
      ...state,
      allDocumentsCount: action.allDocumentsCount,
    })
  ),

  on(
    DocumentsActions.reloadUtilityWidget,
    (state, action) => ({
      ...state,
      reloadUtilityWidget: action.reload,
    })
  ),
);

