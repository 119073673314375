import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {tap} from 'rxjs';
import {Store} from '@ngrx/store';
import {InhaberSelectors} from '../store/selectors/inhaber.selectors';
import {InhaberActions} from '../store/actions/inhaber.actions';
import {AppState} from '../interfaces/app-state.interface';
import {filter} from 'rxjs/operators';
import {ErrorpageActions} from '../store/actions/errorpage.actions';

@Injectable({
  providedIn: 'root'
})
export class InhaberResolver  {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

    /*
     * INFO: Wenn die Fehlermeldungen NICHT manuell embedded werden sollen, wird per redirect die
     * Route auf die error-Seite umgeleitet.
     */
    this.store.select(InhaberSelectors.isEmpty).pipe(
      filter(isEmpty => !!isEmpty && !route.data['embedErrors']),
    ).subscribe(() => {
      this.store.dispatch(ErrorpageActions.betriebNotFound());
    });

    this.store.select(InhaberSelectors.inhabersLoaded).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.select(InhaberSelectors.validateSelectedId).subscribe(() => {
            this.store.dispatch(InhaberActions.validateSelectedId());
          });

          this.store.dispatch(InhaberActions.getInhabers());
        }
      }),
    ).subscribe();
  }
}
