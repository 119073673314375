import {createReducer, on} from '@ngrx/store';
import {DocumentDownloadActions} from '../actions/document-download.actions';
import {DocumentDownloadState} from '../../interfaces/document-download-state.interface';


export const initialDocumentDownloadState: DocumentDownloadState = {
  isLoading: false,
  documentContentData: {
    objectUrl: undefined,
    bezeichnung: undefined,
    dokumentId: undefined,
  },
};

export const documentDownloadReducer = createReducer(
  initialDocumentDownloadState,

  on(
    DocumentDownloadActions.downloadDocument,
    (state) => ({
      ...state,
      documentContentData: initialDocumentDownloadState.documentContentData,
      isLoading: true,
    })
  ),

  on(
    DocumentDownloadActions.documentDownloaded,
    (state, action) => {

      const oldContentData = state.documentContentData;

      if (!oldContentData.objectUrl) {
        return {
          ...state,
          documentContentData: action.data,
          isLoading: false,
        };
      }

      // INFO: Prüfen ob das Dokument bereits heruntergeladen worden ist.
      if (oldContentData.dokumentId === action.data.dokumentId) {

        // INFO: Falls bereits heruntergeladen, nichts tun.
        return {
          ...state,
          isLoading: false,
        };
      } else {

        /*
         * INFO: Falls es ein neues Dokument ist, das alte Dokument mithilfe der objectUrl
         * aus dem Browser-Cache löschen und das neue Dokument in den Store setzen.
         * Aufgrund von Performance wird nur das letzte heruntergeladene Dokument gecached.
         */
        URL.revokeObjectURL(oldContentData.objectUrl);

        return {
          ...state,
          documentContentData: action.data,
          isLoading: false,
        };
      }
    }
  ),
);
