import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


interface IconSpec {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class IconService {
  iconList: IconSpec[] = [
    {
      name: 'folder',
    },
    {
      name: 'folder_new',
    },
    {
      name: 'document',
    },
    {
      name: 'document_new',
    },
    {
      name: 'document_new_th',
    },
    {
      name: 'arrow_left',
    },
    {
      name: 'eye',
    },
    {
      name: 'download',
    },
    {
      name: 'pdf_icon',
    },
    {
      name: 'download_pdf_viewer',
    },
    {
      name: 'search',
    },
    {
      name: 'print',
    },
    {
      name: 'circumflex_left',
    },
    {
      name: 'circumflex_right',
    },
    {
      name: 'rotate_right',
    },
    {
      name: 'close',
    },
    {
      name: 'info',
    },
    {
      name: 'erfolgsrechnung',
    },
    {
      name: 'erfolgsrechnung_new',
    },
    {
      name: 'geldvermoegen',
    },
    {
      name: 'geldvermoegen_new',
    },
    {
      name: 'jahresabschluss',
    },
    {
      name: 'jahresabschluss_new',
    },
    {
      name: 'liquiditaetsuebersicht',
    },
    {
      name: 'liquiditaetsuebersicht_new',
    },
    {
      name: 'unternehmensanalyse',
    },
    {
      name: 'unternehmensanalyse_new',
    },
    {
      name: 'dot',
    },
    {
      name: 'empty-state-widget',
    },
    {
      name: 'gear',
    },
    {
      name: 'access_error',
    },
    {
      name: 'chart',
    },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.name}.svg`)
      );
    });
  }
}
