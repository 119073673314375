import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED, RouterNavigationAction} from '@ngrx/router-store';
import {catchError, concatMap, map, switchMap, tap} from 'rxjs/operators';
import {InhaberActions} from '../actions/inhaber.actions';
import {NGXLogger} from 'ngx-logger';
import {Store} from '@ngrx/store';
import {InhaberSelectors} from '../selectors/inhaber.selectors';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {EMPTY, of} from 'rxjs';
import {initialDocumentsState} from '../reducers/documents.reducer';
import {AppState} from '../../interfaces/app-state.interface';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ErrorpageActions} from '../actions/errorpage.actions';
import {InhaberService} from '../../openapi/dokument-openapi';


@Injectable()
export class InhaberEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private inhaberService: InhaberService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private snackbar: MatSnackBar,
  ) {
  }

  /**
   * @description Selektiert nach jedem Navigationsvorhang die InhaberID aus der URL und setzt diese in den Store.
   * An dieser Stelle kann noch nicht garantiert werden, dass die ID gültig ist. Die Validierung findet in den
   * anschließend getriggerten Effekten statt.
   */
  readonly selectInhaberFromUrl$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ROUTER_NAVIGATED),
        switchMap(action => {
          return this.store.select(InhaberSelectors.selectedId).pipe(
            map(selectedId => ({action: action as RouterNavigationAction, selectedId})),
          );
        }),
        switchMap(data => {
          const inhaberId: string | undefined = data.action.payload.routerState.root.firstChild?.firstChild?.params['inhaberId'];
          if (inhaberId !== data.selectedId) {
            return [
              InhaberActions.setSelectedId({inhaberId}),
            ];
          }
          return EMPTY;
        }),
      ),
  );

  /**
   * @description Lädt alle Inhaber vom Service, die tatsächlich verfügbar sind. Die Liste wird verwendet, um die
   * Gültigkeit der ausgewählten ID zu prüfen.
   */
  readonly getInhabers$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(InhaberActions.getInhabers),
        concatMap(() => this.inhaberService.getInhabers().pipe(
          map(inhaberDtos => {
            if (inhaberDtos.length > 0) {
              this.logger.debug(
                'get inhabers succeeded. inhaberIds:',
                inhaberDtos.map(inhaberDto => inhaberDto.id),
              );

              return InhaberActions.getInhabersSuccess({
                inhaberDtos,
              });
            } else {
              /*
               * INFO: Hat der Anwender kein Recht auf die Dokumentenpostfach App,
               * so werden keine Inhaber vom Backend geladen.
               */
              this.logger.debug('get inhabers succeeded with empty list.');

              return InhaberActions.getInhabersEmpty();
            }
          }),
          catchError(error => of(error).pipe(
            map(error => {
              this.logger.error('get inhabers failed. error:', error);

              return InhaberActions.getInhabersFailure({
                status: error.status,
              });
            }),
          ))
        )),
      ),
  );

  readonly getInhabersFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(InhaberActions.getInhabersFailure),
      tap(() => {
        this.snackbar.open(
          'Fehler beim Laden der Inhaber. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
            panelClass: 'error'
          },
        );
      }),
    ), {dispatch: false}
  );

  /**
   * @description Prüft, ob die ausgewählte Inhaber ID in der Liste der tatsächlich verfügbaren Inhaber enthalten ist.
   * Wenn nicht, wird das erste Element der Liste als designierte (elected) ID verwendet und die URL entsprechend angepasst. Wenn
   * die ausgewählte ID in der Liste tatsächlich vorhanden ist, ist sie gültig und sie wird zur designierten ID.
   */
  readonly validateSelectedId$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(InhaberActions.validateSelectedId),
        concatMap(() => this.store.select(InhaberSelectors.validateSelectedId)),
        map(({
               selectedId,
               inhaberDtos,
             }) => {

          /*
           * INFO: Die ausgewählte ID ist gültig, wenn sie in der Liste der verfügbaren Inhaber existiert.
           * Wenn sie dort nicht gefunden wurde, wird der erste gültige Inhaber aus der Liste als Fallback genommen.
           */
          const elected = inhaberDtos?.find(all => all.id === selectedId) || inhaberDtos?.at(0);

          /*
           * Wenn sich die ausgewählte ID (selected) von der designierten ID (elected) unterscheidet, überwiegt die
           * designierte ID. Die falsch ausgewählte ID wird zur designierten ID korrigiert.
           */
          if (elected && elected.id !== selectedId) {
            return InhaberActions.rectifySelectedId({rectifiedId: elected.id});
          }

          const electedId = elected?.id;

          return InhaberActions.setElectedId({electedId});
        }),
      ),
  );

  /**
   * @description Wenn die ausgewählte ID nicht gültig ist, wird sie auf die Fallback ID geändert (das erste Element aus
   * der Liste der gültigen Inhaber). Die URL wird entsprechend angepasst, es findet also eine Weiterleitung statt.
   */
  readonly rectifySelectedId$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(InhaberActions.rectifySelectedId),
        tap(action => {

          // INFO: Wenn sich die ausgewählte ID ändert, wird die ID auch in der URL angepasst

          if (action.rectifiedId) {
            let oldUrl = this.location.path();

            if (oldUrl.indexOf('inhaber') === -1) {
              oldUrl = oldUrl.replace(/\/?$/, '/inhaber/');
            }

            let newUrl = oldUrl.replace(
              /inhaber(\/?.*?$|\/.*?\/)/,
              'inhaber/' + action.rectifiedId
            );

            // INFO: Sofern auf zu /overview/ gerouted wird, den QueryParam show im default setzen
            if (oldUrl.includes('/overview/')) {
              newUrl += '?show=' + initialDocumentsState.filter.show;
            }

            if (oldUrl === newUrl) {
              return;
            }

            this.router.navigateByUrl(newUrl);
            this.location.replaceState(newUrl);
          }

        }),
      ), {dispatch: false},
  );
}
