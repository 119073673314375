import {createReducer, on} from '@ngrx/store';
import {InhaberActions} from '../actions/inhaber.actions';
import {InhaberState} from '../../interfaces/inhaber.interface';

export const initialInhaberState: InhaberState = {
  inhabersLoading: false,
  inhabersLoaded: false,
};

export const inhaberReducer = createReducer(
  initialInhaberState,

  on(
    InhaberActions.getInhabers,
    (state) => ({
      ...state,
      inhabersLoading: true,
    })
  ),

  on(
    InhaberActions.getInhabersSuccess,
    (state, action) => ({
      ...state,
      inhaberDtos: action.inhaberDtos,
      inhabersLoading: false,
      inhabersLoaded: true,
    })
  ),

  on(
    InhaberActions.getInhabersEmpty,
    (state) => ({
      ...state,
      inhaberDtos: [],
      inhabersLoaded: true,
      inhabersLoading: false,
      electedId: undefined,
    })
  ),

  on(
    InhaberActions.setSelectedId,
    (state, action) => ({
      ...state,
      selectedId: action.inhaberId,
    })
  ),

  on(
    InhaberActions.setElectedId,
    (state, action) => ({
      ...state,
      electedId: action.electedId,
    })
  ),

);
