import {createReducer, on} from '@ngrx/store';
import {EditDialogActions} from '../actions/edit-dialog.actions';
import {EditDialogState} from '../../interfaces/edit-dialog-state.interface';
import {EditActions} from '../actions/edit.actions';


export const initialEditDialogState: EditDialogState = {
  isOpen: false,
};

export const editDialogReducer = createReducer(
  initialEditDialogState,

  on(
    EditDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      dokumentNumber: action.dokumentNumber
    })
  ),

  on(
    EditDialogActions.close,
    () => ({
      ...initialEditDialogState,
    })
  ),

  on(
    EditActions.setDokumentNumber,
    (state, action) => ({
      ...state,
      dokumentNumber: action.dokumentNumber,
    })
  ),
);
