import {routerReducer, RouterState} from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {inhaberReducer, initialInhaberState} from './inhaber.reducer';
import {documentsReducer, initialDocumentsState} from './documents.reducer';
import {documentDownloadReducer, initialDocumentDownloadState} from './document-download.reducer';
import {editDialogReducer, initialEditDialogState} from './edit-dialog.reducer';
import {initialInteractiveAnalysesState, interactiveAnalysesReducer} from './interactive-analyses.reducer';
import {AppState} from '../../interfaces/app-state.interface';
import {errorpageReducer, initialErrorpageState} from './errorpage.reducers';


export const initialDopoState: AppState = {
  router: RouterState.Minimal,
  inhaber: initialInhaberState,
  documents: initialDocumentsState,
  documentDownload: initialDocumentDownloadState,
  editDialog: initialEditDialogState,
  interactiveAnalyses: initialInteractiveAnalysesState,
  errorpageState: initialErrorpageState,
};

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  inhaber: inhaberReducer,
  documents: documentsReducer,
  documentDownload: documentDownloadReducer,
  editDialog: editDialogReducer,
  interactiveAnalyses: interactiveAnalysesReducer,
  errorpageState: errorpageReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
