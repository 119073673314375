import {createAction, props} from '@ngrx/store';
import {InhaberDTO} from '../../openapi/dokument-openapi';


export class InhaberActions {

  public static getInhabers = createAction(
    '[Inhaber Resolver] Load all available inhabers.'
  );

  public static getInhabersSuccess = createAction(
    '[Inhaber Effect] Get inhabers succeeded.',
    props<{ inhaberDtos: InhaberDTO[] }>(),
  );

  public static getInhabersFailure = createAction(
    '[Documents] Get inhabers failed.',
    props<{ status: number }>(),
  );

  public static getInhabersEmpty = createAction(
    '[Documents] Get inhabers was empty.',
  );

  public static validateSelectedId = createAction(
    '[Inhaber Effect, Inhaber Resolver] Validate selected inhaber id.',
  );

  public static setSelectedId = createAction(
    '[Inhaber Effect] Set selected inhaber id.',
    props<{ inhaberId?: string }>(),
  );

  public static rectifySelectedId = createAction(
    '[Inhaber Effect] Rectify selected inhaber id to the elected id.',
    props<{ rectifiedId?: string }>(),
  );

  public static setElectedId = createAction(
    '[Inhaber Effect] Set elected inhaber id.',
    props<{ electedId?: string }>(),
  );

}
