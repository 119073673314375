import {Injectable} from '@angular/core';

import {Store} from '@ngrx/store';
import {InteractiveAnalysesActions} from '../store/actions/interactive-analyses.actions';
import {AppState} from '../interfaces/app-state.interface';


@Injectable({
  providedIn: 'root'
})
export class InteractiveAnalysesResolver  {

  constructor(
    private store: Store<AppState>,
  ) {
  }

  resolve(): void {
    this.store.dispatch(InteractiveAnalysesActions.loadInteractiveAnalysesAll());
  }
}
