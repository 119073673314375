/**
 * Dokumente
 * API für Dokumente
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AblageortDTO } from '../model/models';
import { CountDokumentResponseDTO } from '../model/models';
import { DokumentDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { FilterDokumentDTO } from '../model/models';
import { PageableDokumentDTO } from '../model/models';
import { UpdateDokumentRequestDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DokumentService {

    protected basePath = 'https://api-dev.adnova.de/fnktnext/dokument-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Lesen der Anzahl der Dokumente
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param dokumentFilter Objekt für die Filterung von Dokumenten.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CountDokumentResponseDTO>;
    public countDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CountDokumentResponseDTO>>;
    public countDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CountDokumentResponseDTO>>;
    public countDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling countDokumente.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dokumentFilter !== undefined && dokumentFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dokumentFilter, 'dokumentFilter');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CountDokumentResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente/count`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen eines Ablageorts
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param ablageort Enum Standardablageort von Dokumenten.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAblageort(inhaberId: string, ablageort: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AblageortDTO>;
    public getAblageort(inhaberId: string, ablageort: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AblageortDTO>>;
    public getAblageort(inhaberId: string, ablageort: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AblageortDTO>>;
    public getAblageort(inhaberId: string, ablageort: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getAblageort.');
        }
        if (ablageort === null || ablageort === undefined) {
            throw new Error('Required parameter ablageort was null or undefined when calling getAblageort.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AblageortDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/ablageorte/${encodeURIComponent(String(ablageort))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Laden eines Dokuments
     * Laden der Metadaten über die UUID.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param dokumentId Id die ein Dokument identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokument(inhaberId: string, dokumentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DokumentDTO>;
    public getDokument(inhaberId: string, dokumentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DokumentDTO>>;
    public getDokument(inhaberId: string, dokumentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DokumentDTO>>;
    public getDokument(inhaberId: string, dokumentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getDokument.');
        }
        if (dokumentId === null || dokumentId === undefined) {
            throw new Error('Required parameter dokumentId was null or undefined when calling getDokument.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DokumentDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente/${encodeURIComponent(String(dokumentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Herunterladen eines Dokuments
     * Laden einer Datei über die UUID.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param dokumentId Id die ein Dokument identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokumentContent(inhaberId: string, dokumentId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<Blob>;
    public getDokumentContent(inhaberId: string, dokumentId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpResponse<Blob>>;
    public getDokumentContent(inhaberId: string, dokumentId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<HttpEvent<Blob>>;
    public getDokumentContent(inhaberId: string, dokumentId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getDokumentContent.');
        }
        if (dokumentId === null || dokumentId === undefined) {
            throw new Error('Required parameter dokumentId was null or undefined when calling getDokumentContent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente/${encodeURIComponent(String(dokumentId))}/content`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen der Ablageorte
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAblageorte(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AblageortDTO>>;
    public readAblageorte(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AblageortDTO>>>;
    public readAblageorte(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AblageortDTO>>>;
    public readAblageorte(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readAblageorte.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AblageortDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/ablageorte`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen der Dokumente
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param ablageort Enum Standardablageort von Dokumenten.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readDokumente(inhaberId: string, ablageort: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DokumentDTO>>;
    public readDokumente(inhaberId: string, ablageort: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DokumentDTO>>>;
    public readDokumente(inhaberId: string, ablageort: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DokumentDTO>>>;
    public readDokumente(inhaberId: string, ablageort: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readDokumente.');
        }
        if (ablageort === null || ablageort === undefined) {
            throw new Error('Required parameter ablageort was null or undefined when calling readDokumente.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DokumentDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/ablageorte/${encodeURIComponent(String(ablageort))}/dokumente`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Suchen von Dokumenten
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param dokumentFilter Objekt für die Filterung von Dokumenten.&lt;br&gt; 
     * @param dokumentPageable Objekt für das OffSet, Limit und die Sortierung beim Laden von Dokumenten.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, dokumentPageable?: PageableDokumentDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DokumentDTO>>;
    public searchDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, dokumentPageable?: PageableDokumentDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DokumentDTO>>>;
    public searchDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, dokumentPageable?: PageableDokumentDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DokumentDTO>>>;
    public searchDokumente(inhaberId: string, dokumentFilter?: FilterDokumentDTO, dokumentPageable?: PageableDokumentDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling searchDokumente.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dokumentFilter !== undefined && dokumentFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dokumentFilter, 'dokumentFilter');
        }
        if (dokumentPageable !== undefined && dokumentPageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dokumentPageable, 'dokumentPageable');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DokumentDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisieren eines Dokuments
     * Aktualisieren des Gelesen-Kennzeichens zu einem Dokument. 
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param dokumentId Id die ein Dokument identifiziert.&lt;br&gt; 
     * @param updateDokumentRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDokumentReadMark(inhaberId: string, dokumentId: string, updateDokumentRequestDTO: UpdateDokumentRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateDokumentReadMark(inhaberId: string, dokumentId: string, updateDokumentRequestDTO: UpdateDokumentRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateDokumentReadMark(inhaberId: string, dokumentId: string, updateDokumentRequestDTO: UpdateDokumentRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateDokumentReadMark(inhaberId: string, dokumentId: string, updateDokumentRequestDTO: UpdateDokumentRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateDokumentReadMark.');
        }
        if (dokumentId === null || dokumentId === undefined) {
            throw new Error('Required parameter dokumentId was null or undefined when calling updateDokumentReadMark.');
        }
        if (updateDokumentRequestDTO === null || updateDokumentRequestDTO === undefined) {
            throw new Error('Required parameter updateDokumentRequestDTO was null or undefined when calling updateDokumentReadMark.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente/${encodeURIComponent(String(dokumentId))}`,
            updateDokumentRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Setzen des Gelesen-Kennzeichens
     * Alle ungelesenen Dokumente im Ablageort werden auf gelesen gesetzt.
     * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt; 
     * @param ablageort Enum Standardablageort von Dokumenten.&lt;br&gt; 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReadMark(inhaberId: string, ablageort: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateReadMark(inhaberId: string, ablageort: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateReadMark(inhaberId: string, ablageort: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateReadMark(inhaberId: string, ablageort: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateReadMark.');
        }
        if (ablageort === null || ablageort === undefined) {
            throw new Error('Required parameter ablageort was null or undefined when calling updateReadMark.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/dokumente/${encodeURIComponent(String(ablageort))}/gelesen`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
