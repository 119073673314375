import {createAction, props} from '@ngrx/store';
import {DocumentContentData} from '../../interfaces/document-content-data.interface';
import {DokumentDTO} from '../../openapi/dokument-openapi';


export class DocumentDownloadActions {

  public static downloadDocument = createAction(
    '[Document download] Start download a dokument.',
    props<{ inhaberId: string, dokumentId: string, bezeichnung?: string, save?: boolean, dokument?: DokumentDTO }>(),
  );

  public static documentDownloaded = createAction(
    '[Document download] Downloaded a dokument.',
    props<{ data: DocumentContentData }>(),
  );

}
