import {createAction, props} from '@ngrx/store';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {ShowContext} from '../../types/show-context.type';
import {DocumentsFilter} from '../../interfaces/documents-filter.interface';
import {Ablageort} from '../../interfaces/ablageort.interface';
import {AblageortDTO, DokumentDTO, UpdateDokumentRequestDTO} from '../../openapi/dokument-openapi';


export class DocumentsActions {

  public static changeShowContext = createAction(
    '[Documents] Change show context.',
    props<{ show: ShowContext, path?: string }>(),
  );

  public static changeSearchtext = createAction(
    '[Documents] Change search text.',
    props<{ searchtext: string }>(),
  );

  public static loadDocuments = createAction(
    '[Documents] Start loading documents.',
    props<{
      inhaberId: string,
      filter: DocumentsFilter,
      sort?: string,
      pagination?: PageEvent,
    }>(),
  );

  public static documentsLoaded = createAction(
    '[Documents] Documents loaded.',
    props<{ inhaberId: string, ablageort?: string, dokumente: DokumentDTO[] }>(),
  );

  public static loadAllAblageorte = createAction(
    '[Documents] Start loading all given ablageorte.',
    props<{ inhaberIds: string[] }>(),
  );

  public static loadSingleAblageorte = createAction(
    '[Documents] Start loading single given ablageort.',
    props<{ inhaberId: string }>(),
  );

  public static loadSingleAblageortSuccess = createAction(
    '[Documents] Loading single Ablageort succeeded.',
    props<{ inhaberId: string, ablageorte: AblageortDTO[] }>(),
  );

  public static loadSingleAblageorteFailure = createAction(
    '[Documents] Loading single Ablageort failed.',
    props<{ status: number }>(),
  );

  public static ablageorteLoaded = createAction(
    '[Documents] Ablageorte loaded.',
    props<{ inhaberId: string, ablageorte: Ablageort[] }>(),
  );

  public static setPagination = createAction(
    '[Documents] Set pagination.',
    props<{ page?: PageEvent }>(),
  );

  public static updateDocument = createAction(
    '[Documents] Updates the document.',
    props<{
      inhaberId: string,
      document: DokumentDTO,
      requestDto: UpdateDokumentRequestDTO,
    }>(),
  );

  public static updateDocumentSuccess = createAction(
    '[Documents] Document update succeeded.',
    props<{ document: DokumentDTO, requestDto: UpdateDokumentRequestDTO }>(),
  );

  public static updateDocumentFailure = createAction(
    '[Documents] Document update failed.',
    props<{ status: number }>(),
  );

  public static setFilterPaginationLength = createAction(
    '[Documents] Set length of pagination.',
    props<{ length: number }>(),
  );

  public static setFilterPaginationPageIndex = createAction(
    '[Documents] Set pageIndex of pagination.',
    props<{ pageIndex: number }>(),
  );

  public static setSort = createAction(
    '[Documents] Set the sort direction of documents.',
    props<{ sort: string }>(),
  );

  public static setAllDocumentsCount = createAction(
    '[Documents] Set the count of documents.',
    props<{ allDocumentsCount: number }>(),
  );

  public static reloadUtilityWidget = createAction(
    '[Intent Action Service] Reload utility-widget.',
    props<{ reload: boolean }>(),
  );

}
