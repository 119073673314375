import {createAction, props} from '@ngrx/store';


export class EditDialogActions {

  public static open = createAction(
    '[Edit dialog] Open dialog.',
    props<{ dokumentNumber: number }>(),
  );

  public static close = createAction(
    '[Edit dialog] Close dialog.',
  );

}
