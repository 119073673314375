import {createAction, props} from '@ngrx/store';
import {DashboardsAuswahlExtendedResponseDTO} from '../../openapi/tableau-openapi';
import {InteractiveAnalysisData} from '../../interfaces/interactive-analysis-data';
import {LatestLoadedDashboardData} from '../../interfaces/latest-loaded-dashboard-data';


export class InteractiveAnalysesActions {
  public static loadInteractiveAnalysesAll = createAction(
    '[InteractiveAnalyses] Load all interactive-analyses.',
  );

  public static interactiveAnalysesAllLoaded = createAction(
    '[InteractiveAnalyses] All interactive-analyses loaded.',
    props<{
      response: DashboardsAuswahlExtendedResponseDTO,
    }>(),
  );

  public static loadInteractiveAnalysesUrl = createAction(
    '[InteractiveAnalyses] Load interactive-analysis url.',
    props<{
      interactiveAnalysis: InteractiveAnalysisData,
      inhaberId: string,
    }>(),
  );

  public static interactiveAnalysesUrlLoaded = createAction(
    '[InteractiveAnalyses] Interactive-analysis url loaded.',
    props<{
      data: LatestLoadedDashboardData,
    }>(),
  );

  public static clear = createAction(
    '[InteractiveAnalyses] Clear all store data relating to interactive analyses.',
  );
}
