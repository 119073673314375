import {createAction, props} from '@ngrx/store';


export class ErrorpageActions {

  public static dokumentServiceUnavailable = createAction(
    '[Https Interceptor] Shows dokument-service unavailable error-page.',
    props<{
      status: number,
      url: string,
    }>(),
  );

  public static betriebNotFound = createAction(
    '[Inhaber Effect] Shows betrieb not found error-page.',
  );

}
