import {StandardablageortDTO} from './openapi/dokument-openapi';


export const displayedAblageorte = [
  StandardablageortDTO.Fakturierung,
  StandardablageortDTO.GrundUndBoden,
  StandardablageortDTO.Lohnbuchhaltung,
  StandardablageortDTO.Rechnungswesen,
  StandardablageortDTO.Sonstiges,
  StandardablageortDTO.Steuern,
  StandardablageortDTO.Vertraege,
  StandardablageortDTO.Zahlungsverkehr,
] as string[];
