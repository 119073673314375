import {createReducer, on} from '@ngrx/store';
import {InteractiveAnalysesState} from '../../interfaces/interactive-analyses-state';
import {InteractiveAnalysesActions} from '../actions/interactive-analyses.actions';
import {InteractiveAnalysisData} from '../../interfaces/interactive-analysis-data';
import {DocumentsActions} from '../actions/documents.actions';
import {InhaberAblageorte} from '../../interfaces/inhaber-ablageorte.interface';


export const initialInteractiveAnalysesState: InteractiveAnalysesState = {
  interactiveAnalyses: [],
  interactiveAnalysesInitialLoaded: false,
};

export const interactiveAnalysesReducer = createReducer(
  initialInteractiveAnalysesState,

  on(InteractiveAnalysesActions.interactiveAnalysesAllLoaded,
    (state, action) => {

      /*
       * INFO: Die Daten zu Anfang in eine für unsere Zwecke passende Datenstruktur bringen.
       *
       * Zwar könnten die Daten auch in der Struktur bleiben wie wir sie vom Service erhalten,
       * jedoch haben wir auf diesem Wege langfristig einen deutlichen Vorteil.
       * Unsere App braucht lediglich eine Liste mit Dashboards + InhaberId.
       * Die Daten direkt umzustrukturieren ist hierbei eine Designentscheidung aus folgenden Gründen:
       * - Deutliche Reduzierung von für uns nicht notwendiger Komplexität
       * - Weniger Prüfungen auf Werte die potenziell undefined sind
       * - Passendere Bezeichnungen für Objekte und Felder
       */
      let interactiveAnalyses: InteractiveAnalysisData[] = [];

      // INFO: Gemischte Liste aller Dashboards für alle Betriebe
      const betriebsDashboards = action.response.betriebsDashboards;
      if (betriebsDashboards) {
        for (const betriebDashboard of betriebsDashboards) {

          // INFO: Liste aller Dashboard je Betrieb
          const betriebDashboards = betriebDashboard.dashboards;
          if (betriebDashboards) {
            for (const dashboardDetailData of betriebDashboards) {

              interactiveAnalyses.push({
                inhaberId: betriebDashboard.firmaId,
                detailData: dashboardDetailData,
                globalDashboard: false,
              });
            }
          }
        }
      }

      // INFO: Liste aller globalen Dashboards, ohne Betriebszugehörigkeit
      const globalDashboards = action.response.globalDashboards;
      if (globalDashboards) {
        for (const dashboard of globalDashboards) {
          interactiveAnalyses.push({
            detailData: dashboard,
            globalDashboard: true,
          });
        }
      }

      /*
       * INFO: Nicht alle potenziell anzeigbaren Dashboards sollen dem Anwender präsentiert werden.
       * Wir lassen ausschließlich die Dashboards mit folgenden Identifiern zu:
       * - unternehmensanalyse
       * - geldvermoegen
       * - erfolgsrechnung
       * - liquiditaetsuebersicht
       * - jahresabschlussBwl
       * - jahresabschlussStl
       * - jahresabschlussHgb
       *
       * Der Grund ist, dass wir bewusst steuern wollen, welche Dashboards angezeigt werden.
       */
      const allowedDashboard = [
        'erfolgsrechnung',
        'geldvermoegen',
        'jahresabschlussBwl',
        'jahresabschlussStl',
        'jahresabschlussHgb',
        'liquiditaetsuebersicht',
        'unternehmensanalyse',
      ];
      interactiveAnalyses = interactiveAnalyses.filter(analysis => {
        return allowedDashboard.includes(analysis.detailData.nameIdentifier!);
      });

      return {
        ...state,
        interactiveAnalyses,
        interactiveAnalysesInitialLoaded: true,
      };
    }),

  on(
    InteractiveAnalysesActions.interactiveAnalysesUrlLoaded,
    (state, action) => {
      return {
        ...state,
        interactiveAnalyses: [
          ...state.interactiveAnalyses.map(analyse => {
            if (
              analyse.inhaberId === action.data.interactiveAnalysisData.inhaberId &&
              analyse.detailData.nameIdentifier === action.data.interactiveAnalysisData.detailData.nameIdentifier
            ) {
              return {
                ...analyse,
                detailData: {
                  ...analyse.detailData,
                  gelesen: true,
                }
              };
            }
            return analyse;
          }),
        ],
        latestLoadedDashboardData: action.data,
      };
    }
  ),

  on(
    InteractiveAnalysesActions.clear,
    (state) => ({
      ...state,
      latestLoadedDashboardData: undefined,
    })
  ),

);
