import {createAction, props} from '@ngrx/store';


export class EditActions {

  public static setDokumentNumber = createAction(
    '[Edit] Set the current number of the document.',
    props<{ dokumentNumber: number }>(),
  );

}
