import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Config, ConfigService} from '../config/service/config.service';
import {Store} from '@ngrx/store';
import {AppState} from '../interfaces/app-state.interface';
import {ErrorpageActions} from '../store/actions/errorpage.actions';


/**
 * Unser HttpInterceptor soll sich um die 500er-Fehler kümmern,
 * da diese immer für jeden REST-Call identisch sind.
 * Hier soll entschieden werden wie kritisch der Fehler behandelt werden soll.
 */
@Injectable()
export class Interceptor implements HttpInterceptor {

  private readonly unsubscribe$ = new Subject<void>();

  private config?: Config;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private store: Store<AppState>,
  ) {
    this.configService.loaded$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(config => {
      this.config = config;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status) {
          switch (error.status) {
            case 500:
            case 502:
            case 503:
              /*
               * INFO: Hier muss immer geprüft werden, welcher Service den Fehler verursacht.
               * Dann erst kann entschieden werden wie kritisch der Fehler ist
               * und ob es einer Behandlung (Errorpage, Snackbar, ...) benötigt
               */
              if (error.url.indexOf(this.config?.dokument.apiBaseUrl) >= 0) {
                this.store.dispatch(ErrorpageActions.dokumentServiceUnavailable({
                  status: error.status,
                  url: error.url,
                }));
              }

              return EMPTY;
          }
        }

        return throwError(error);
      })
    );
  }
}
