import {Injectable} from '@angular/core';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Router} from '@angular/router';
import {from} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {DocumentsActions} from '../store/actions/documents.actions';
import {AppState} from '../interfaces/app-state.interface';
import {Store} from '@ngrx/store';
import {InteractiveAnalysesActions} from '../store/actions/interactive-analyses.actions';


export interface DocumentReadIdV1 {
  readonly inhaberId: string;
  readonly documentId: string;
  readonly ablageortBezeichnung: string;
}

export interface OpenAblageortV1 {
  readonly inhaberId: string;
  readonly path: string;
}

export interface OpenAnalyseV1 {
  readonly inhaberId: string;
  readonly analyseIdentifier: string;
}

export interface ReadAnalyseV1 {
  readonly inhaberId: string;
  readonly analyseIdentifier: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  /**
   * Intent-Id für den Broadcast zur Information über ein gelesenes Dokument
   *
   * JSON Schema: src/assets/intents/de.just-farming.dokumentenpostfach_document.read_v1.json
   *
   * @private
   */
  private readonly dopoDocumentReadIdV1 = 'de.just-farming:dokumentenpostfach:document.read';

  /**
   * Intent-Id für das Öffnen eines Ablageortes
   *
   * JSON Schema: src/assets/intents/de.just-farming.dokumentenpostfach_ablageort.open_v1.json
   *
   * @private
   */
  private readonly dopoOpenAblageortV1 = 'de.just-farming:dokumentenpostfach:ablageort.open';

  /**
   * Intent-Id für das Öffnen einer Analyse
   *
   * JSON Schema: src/assets/intents/de.just-farming.dokumentenpostfach_analyse.open_v1.json
   *
   * @private
   */
  private readonly dopoOpenAnalyseV1 = 'de.just-farming:dokumentenpostfach:analyse.open';

  /**
   * Intent-Id für das Lesen einer Analyse als Broadcast
   *
   * JSON Schema: src/assets/intents/de.just-farming.dokumentenpostfach_analyse.read_v1.json
   *
   * @private
   */
  private readonly dopoReadAnalyseV1 = 'de.just-farming:dokumentenpostfach:analyse.read';


  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
    private router: Router,
    private store: Store<AppState>,
  ) {
    if (this.isRunningInPortal()) {

      this.portalMessageBroker.registerIntentCallback(this.dopoDocumentReadIdV1, '1', (data => {
        this.handleDocumentReadIdV1(data);
      }));

      this.portalMessageBroker.registerIntentCallback(this.dopoOpenAblageortV1, '1', (data => {
        this.handleOpenAblageortV1(data);
      }));

      this.portalMessageBroker.registerIntentCallback(this.dopoOpenAnalyseV1, '1', (data => {
        this.handleOpenAnalyseV1(data);
      }));

      this.portalMessageBroker.registerIntentCallback(this.dopoReadAnalyseV1, '1', (data => {
        this.handleReadAnalyseV1(data);
      }));

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft, ob die App im Portal läuft.
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  /**
   * Wird ein Dokument angelegt, sollen die Tabellen und Badges darüber informiert werden.
   *
   * @param data Intent-Daten
   */
  public documentReadIdV1(data: DocumentReadIdV1): void {
    if (this.isRunningInPortal()) {
      this.doDocumentReadIdV1(data);
    } else {
      this.handleDocumentReadIdV1(data);
    }
  }

  private handleDocumentReadIdV1(
    data: DocumentReadIdV1
  ): void {
    this.store.dispatch(DocumentsActions.reloadUtilityWidget({
      reload: true,
    }));
  }

  private doDocumentReadIdV1(data: DocumentReadIdV1): void {
    this.doEmit(this.dopoDocumentReadIdV1, '1', data);
  }

  /**
   * Öffnet einen Ablageort
   *
   * @param data Intent-Daten
   */
  public openAblageortV1(data: OpenAblageortV1): void {
    if (this.isRunningInPortal()) {
      this.doOpenAblageortV1(data);
    } else {
      this.handleOpenAblageortV1(data);
    }
  }

  private handleOpenAblageortV1(
    data: OpenAblageortV1
  ): void {
    this.router.navigate(
      [`/overview/inhaber/${data.inhaberId}`],
      {
        queryParams: {
          show: 'folders',
          path: data.path,
        }
      }
    );
  }

  private doOpenAblageortV1(data: OpenAblageortV1): void {
    this.doEmit(this.dopoOpenAblageortV1, '1', data);
  }

  /**
   * Öffnet eine interaktive Analyse
   *
   * @param data Intent-Daten
   */
  public openAnalyseV1(data: OpenAnalyseV1): void {
    if (this.isRunningInPortal()) {
      this.doOpenAnalyseV1(data);
    } else {
      this.handleOpenAnalyseV1(data);
    }
  }

  private handleOpenAnalyseV1(
    data: OpenAnalyseV1
  ): void {

    // INFO: Hier muss vor dem Navigieren erst die Komponente abgeräumt und dann anschließend neu initialisiert werden,
    // da ansonsten nur die zuerst geladenen analyse angezeigt wird.
    this.router.navigate(
      [`/interactive-analysis/inhaber/${data.inhaberId}`],
      {
        queryParams: {
          'dashboard-identifier': data.analyseIdentifier,
        },
      }
    ).then(() => {
      this.store.dispatch(InteractiveAnalysesActions.clear());
    });
  }

  private doOpenAnalyseV1(data: OpenAnalyseV1): void {
    this.doEmit(this.dopoOpenAnalyseV1, '1', data);
  }

  /**
   * Informiert über das Lesen einer interaktiven Analyse
   *
   * @param data Intent-Daten
   */
  public readAnalyseV1(data: ReadAnalyseV1): void {
    if (this.isRunningInPortal()) {
      this.doReadAnalyseV1(data);
    } else {
      this.handleReadAnalyseV1(data);
    }
  }

  private handleReadAnalyseV1(
    data: ReadAnalyseV1
  ): void {
    this.store.dispatch(DocumentsActions.reloadUtilityWidget({
      reload: true,
    }));
  }

  private doReadAnalyseV1(data: ReadAnalyseV1): void {
    this.doEmit(this.dopoReadAnalyseV1, '1', data);
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}

